import NotFound from '@components/NotFound';
import fetchSiteWideData from '@features/WordPress/graphql/handlers/fetch-site-wide-data';
// import fetchSiteWideData from '@features/WordPress/graphql/handlers/fetch-site-wide-data';

/**
 * NOTE: This page is generated on build time. So footer/header will be out of sync.
 * It's not the best solution.
 *
 * TODO: Implement a Custom Error page.
 */

export async function getStaticProps(context) {
  const { generalOptions } = await fetchSiteWideData(context.locale);

  return {
    props: {
      generalOptions: generalOptions || {},
    },
  };
}

export default function PageNotFound() {
  return <NotFound />;
}
